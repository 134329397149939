/* global googletag, gptadslots */
import { dfpManager } from '../../../base/js/dfp/dfp-manager';


if (window.googletag && window.gptadslots) {
  googletag.cmd.push(() => {
    const mapping = googletag.sizeMapping()
      .addSize([1220, 0], [728, 90])
      .addSize([1024, 0], [])
      .addSize([768, 0], [728, 90])
      .addSize([0, 0], [320, 50])
      .build();

    gptadslots[21] = googletag.defineSlot('/55877742/Corrections1',
      [[300, 250]],
      'div-gpt-ad-213395177806553216-21')
      .addService(googletag.pubads());

    gptadslots[20] = googletag
      .defineSlot('/55877742/Corrections1',
        [[728, 90]],
        'div-gpt-ad-213395177806553216-20')
      .setTargeting('Pos', ['LB2'])
      .defineSizeMapping(mapping)
      .addService(googletag.pubads());

    dfpManager.setTargets(googletag);
    dfpManager.enable();
  });
}
